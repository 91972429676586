import * as types from '../actions/actionTypes';
import TargetedCampaign from '../entities/targetedCampaign';

const targetCampaigInitialState = {
    /* userReadableProperty: 'name', */
    loadingDependencies: false,
    loaded: false,
    filter: { msisdn: '', campaignCode: '' },
    data: [],
};

type TargetCampaignStateType = typeof targetCampaigInitialState;
type TargetCampaignFilterType = typeof targetCampaigInitialState.filter;

const targetCampaigns = (state = targetCampaigInitialState, action: any) => {
    switch (action.type) {
        case types.TARGET_CAMPAIGN_FETCH:
            return {
                ...state,
                data: action.data.map((targetedCampaign: TargetedCampaign) => {
                    return { ...targetedCampaign };
                }),
                loaded: true,
            };
        case types.TARGET_CAMPAIGN_SET_FILTER:
            return {
                ...state,
                filter: action.data,
            };
        case types.TARGET_CAMPAIGNM_RESET_FILTER:
            return {
                ...state,
                filter: targetCampaigInitialState.filter,
            };
        default:
            return state;
    }
};

export default targetCampaigns;

export const isLoaded = (state: any): boolean => {
    return state.targetCampaigns.loaded === true;
};

export const getData = (state: any): TargetedCampaign[] => {
    return state.targetCampaigns.data;
};

export const getState = (state: any): TargetCampaignStateType => {
    return state.targetCampaigns;
};

export const getFilter = (state: any): TargetCampaignFilterType => {
    return state.targetCampaigns.filter;
};

