import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import VoucherClaimDetail from './voucherClaimDetail';
import { createVoucherClaim } from '../../actions/voucherClaimActions';
import { navigateToVoucherClaims } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import { VOUCHER_CLAIM_SOURCE_ENUM, VOUCHER_CLAIM_STATUS_ENUM, VOUCHER_CLAIM_VARIANT_ENUM } from '../../common/enums';
import VoucherClaimCreateMutation from '../../entities/voucherClaimCreateMutation';

const initialValues = new VoucherClaimCreateMutation({
    msisdn: '',
    note: '',
    source: VOUCHER_CLAIM_SOURCE_ENUM.CustomerCare,
    variant: VOUCHER_CLAIM_VARIANT_ENUM.Fifty,
});

interface VoucherClaimDetailCreateProps extends BaseDetailProps<VoucherClaimCreateMutation> {}

const VoucherClaimDetailCreate = ({ submitAction, cancelAction, t }: VoucherClaimDetailCreateProps) => {
    return (
        <VoucherClaimDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('VoucherClaimCreateLabel')}
            cancelAction={cancelAction}
            t={t}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<VoucherClaimDetailCreateProps>): BaseDetailDispatchProps<VoucherClaimCreateMutation> => {
    return {
        submitAction: bindActionCreators(createVoucherClaim, dispatch),
        cancelAction: bindActionCreators(navigateToVoucherClaims, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(VoucherClaimDetailCreate);
