import * as types from '../actions/actionTypes';
import VoucherClaim from '../entities/voucherClaim';

const voucherClaimInitialState = {
    /* userReadableProperty: 'name', */
    loadingDependencies: false,
    loaded: false,
    searchMsisdn: null,
    data: [],
};

type VoucherClaimStateType = typeof voucherClaimInitialState;

const voucherClaims = (state = voucherClaimInitialState, action: any) => {
    switch (action.type) {
        case types.VOUCHERCLAIM_FETCH:
            return {
                ...state,
                data: action.data.map((voucherClaim: VoucherClaim) => {
                    return { ...voucherClaim };
                }),
                loaded: true,
            };
        case types.VOUCHERCLAIM_SET_SEARCH_MSISDN:
            return {
                ...state,
                searchMsisdn: action.data,
            };
        case types.VOUCHERCLAIM_RESET_SEARCH_MSISDN:
            return {
                ...state,
                searchMsisdn: null,
            };
        default:
            return state;
    }
};

export default voucherClaims;

export const isLoaded = (state: any): boolean => {
    return state.voucherClaims.loaded === true;
};

export const getData = (state: any): VoucherClaim[] => {
    return state.voucherClaims.data;
};

export const getState = (state: any): VoucherClaimStateType => {
    return state.voucherClaims;
};

/* export const getUserReadableProperty = (state: any): keyof Role => {
    return state.roles.userReadableProperty;
}; */
