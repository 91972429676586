import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToVoucherClaims } from './navigationActions';
import * as tableActions from './tableActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import VoucherClaim from '../entities/voucherClaim';
import voucherClaimItemTransformationBeforeSave from '../data-transformations/voucherClaimItemTransformationBeforeSave';
import { hideLoadingSpinner, showLoadingSpinner } from './loadingSpinnerActions';
import VoucherClaimCreateMutation from '../entities/voucherClaimCreateMutation';
import VoucherClaimCreateItemTransformationBeforeSave from '../data-transformations/voucherClaimCreateItemTransformationBeforeSave';
import { loadData } from './tableActions';

/* export const fetchVoucherClaims = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());
        dispatch(showLoadingSpinner());

        const response = await axios.get(`voucherClaim/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.VOUCHERCLAIM_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
        dispatch(hideLoadingSpinner());
    } catch (error) {
        dispatch(ajaxCallError());
        dispatch(hideLoadingSpinner());
        throw error;
    }
}; */

export const updateVoucherClaim = (data: VoucherClaim) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());
    dispatch(showLoadingSpinner());

    const transformedData = voucherClaimItemTransformationBeforeSave(data);

    axios
        .put(`voucherClaim`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.VOUCHERCLAIM_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToVoucherClaims());
            } else {
                dispatch(ajaxCallError());
            }

            dispatch(hideLoadingSpinner());
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const createVoucherClaim = (data: VoucherClaimCreateMutation) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());
    dispatch(showLoadingSpinner());

    const transformedData = VoucherClaimCreateItemTransformationBeforeSave(data);

    axios
        .post(`voucherClaims`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.VOUCHERCLAIM_ADD,
                    data: response.data,
                });

                dispatch(navigateToVoucherClaims());
            } else {
                dispatch(ajaxCallError());
            }

            dispatch(hideLoadingSpinner());
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(hideLoadingSpinner());

            throw error;
        });
};

export const setSearchMsisdn = (msisdn: string) => (dispatch: Dispatch<any>) => {
    dispatch({ type: types.VOUCHERCLAIM_SET_SEARCH_MSISDN, data: msisdn });
};

export const resetSearchMsisdn = () => (dispatch: Dispatch<any>) => {
    dispatch({ type: types.VOUCHERCLAIM_RESET_SEARCH_MSISDN });
};

export const filterByMsisdn = (msisdn: string) => (dispatch: Dispatch<any>) => {
    const filters = [
        {
            name: 'msisdn',
            field: 'msisdn',
            operator: 'equal',
            value: msisdn,
            logicalOperator: 0,
        },
    ];

    tableActions.filterChange(filters)(dispatch);
};

export const setAsUnused = (note: string) => (dispatch: Dispatch<any>, getState: any) => {
    dispatch(beginAjaxCall());
    dispatch(showLoadingSpinner());

    let { table } = getState();
    let { url, data } = table;

    let { extId } = data.find((i: any) => i.selected);

    axios
        .put(`${url}/setClaimAsUnused`, { extId, note })
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.VOUCHERCLAIM_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToVoucherClaims());
                dispatch(loadData(true));
            } else {
                dispatch(ajaxCallError());
            }

            dispatch(hideLoadingSpinner());
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(hideLoadingSpinner());
            throw error;
        });
};
