import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';
import VoucherClaimCreateMutation from '../../entities/voucherClaimCreateMutation';

const nameof = nameofFactory<VoucherClaimCreateMutation>();

export interface VoucherClaimDetailProps extends BaseDetailProps<VoucherClaimCreateMutation>, VoucherClaimDetailStateProps {
    headerText: string;
}

export interface VoucherClaimDetailStateProps {
    lang: string;
}

class VoucherClaimDetail extends Component<VoucherClaimDetailProps> {
    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as VoucherClaimCreateMutation);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as VoucherClaimCreateMutation;
        const errors: Partial<Record<keyof VoucherClaimCreateMutation, string>> = {};

        if (!typedValues.msisdn) {
            errors.msisdn = t('MsisdnIsRequired');
        }

        if (typedValues.msisdn && (!typedValues.msisdn.startsWith('420') || typedValues.msisdn.length != 12)) {
            errors.msisdn = t('Invalid format (420123456789)');
        }

        if (!typedValues.note) {
            errors.note = t('NoteIsRequired');
        }

        if (!typedValues.sourceEnumDescription) {
            errors.sourceEnumDescription = t('ValueIsRequired');
        }

        if (!typedValues.variantEnumDescription) {
            errors.variantEnumDescription = t('ValueIsRequired');
        }

        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('msisdn')}>
                                        <span>
                                            <Translation tKey="Msisdn" />
                                        </span>
                                        <Field name={nameof('msisdn')} component={Input} />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('note')}>
                                        <span>
                                            <Translation tKey="Description" />
                                        </span>
                                        <Field name={nameof('note')} component={Input} />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('sourceEnumDescription')}>
                                        <span>
                                            <Translation tKey="VoucherClaimSource" />
                                        </span>
                                        <Field
                                            name={nameof('sourceEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.VOUCHER_CLAIM_SOURCE}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('variantEnumDescription')}>
                                        <span>
                                            <Translation tKey="VoucherClaimVariant" />
                                        </span>
                                        <Field
                                            name={nameof('variantEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.VOUCHER_CLAIM_VARIANT}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): VoucherClaimDetailStateProps => {
    return {
        lang: state.app.lang,
    };
};

export default connect(mapStateToProps)(VoucherClaimDetail);
