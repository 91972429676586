import React from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import SideBar from '../common/SideBar';
import Header from '../common/Header';
import * as userActions from '../../actions/userActions';

import News from '../news/News';
import NewsDetailCreate from '../news/NewsDetailCreate';
import NewsDetailUpdate from '../news/NewsDetailUpdate';

import Dashboard from '../dashboard/dashboard';

import Extra from '../extra/Extra';

import BetaTester from '../betatester/betatester';
import BetaTesterDetailUpdate from '../betatester/betatesterDetailUpdate';
import BetaTesterDetailCreate from '../betatester/betatesterDetailCreate';

import ChargingCode from '../chargingCode/chargingCode';
import ChargingCodeDetailUpdate from '../chargingCode/chargingCodeDetailUpdate';
import ChargingCodeDetailCreate from '../chargingCode/chargingCodeDetailCreate';

import Users from '../users/users';
import UserDetailCreate from '../users/userDetailCreate';
import UserDetailUpdate from '../users/userDetailUpdate';

import Roles from '../role/roles';
import RoleDetailUpdate from '../role/roleDetailUpdate';
import RoleDetailCreate from '../role/roleDetailCreate';

import Competition from '../competition/competitions';
import CompetitionDetailCreate from '../competition/competitionDetailCreate';
import CompetitionDetailUpdate from '../competition/competitionDetailUpdate';

import Reports from '../reports/reports';
import ReportDetailUpdate from '../reports/reportDetailUpdate';
import ReportDetailCreate from '../reports/reportDetailCreate';
import ReportFilters from '../reports/reportFilters';

import Ratings from '../rating/rating';
import RatingDetailUpdate from '../rating/ratingDetailUpdate';

import RatingSettings from '../ratingSettings/ratingSettings';

import Ecomail from '../ecomail/ecomail';

import OutageNotifications from '../outageNotification/outageNotification';
import OutageNotificationDetailUpdate from '../outageNotification/outageNotificationDetailUpdate';
import OutageNotificationDetailCreate from '../outageNotification/outageNotificationDetailCreate';

import TargetCampaign from '../targetCampaign/targetCampaign';
import TargetCampaignDetailCreate from '../targetCampaign/targetCampaignDetailCreate';

import Rewards from '../reward/rewards';
import RewardDetailCreate from '../reward/rewardDetailCreate';
import RewardDetailUpdate from '../reward/rewardDetailUpdate';

import LoadingSpinnerContainer from '../common/loadingSpinner/loadingSpinnerContainer';
import { NAVIGATION_PATHS } from '../../common/constants';
import SimCardTypes from '../simCardType/simCardTypes';
import SimCardTypeDetailCreate from '../simCardType/simCardTypeDetailCreate';
import SimCardTypeDetailUpdate from '../simCardType/simCardTypeDetailUpdate';

import AffiliateProgramPartners from '../affiliateProgram/affiliateProgramPartners';
import AffiliateProgramPartnerDetailCreate from '../affiliateProgram/affiliateProgramPartnerDetailCreate';
import AffiliateProgramPartnerDetailUpdate from '../affiliateProgram/affiliateProgramPartnerDetailUpdate';

import AffiliateProgramRewards from '../affiliateProgram/affiliateProgramRewards';
import AffiliateProgramRewardDetailCreate from '../affiliateProgram/affiliateProgramRewardDetailCreate';
import AffiliateProgramRewardDetailUpdate from '../affiliateProgram/affiliateProgramRewardDetailUpdate';

import AffiliateProgramSubscriberRewardClaims from '../affiliateProgram/affiliateProgramSubscriberRewardClaims';
import AffiliateProgramSubscriberRewardClaimDetailCreate from '../affiliateProgram/affiliateProgramSubscriberRewardClaimDetailCreate';

import GameClans from '../gameClans/gameClans';

import Texts from '../text/texts';
import TextDetailCreate from '../text/textDetailCreate';
import TextDetailUpdate from '../text/textDetailUpdate';

import Links from '../link/links';
import LinkDetailCreate from '../link/linkDetailCreate';
import LinkDetailUpdate from '../link/linkDetailUpdate';

import DataSources from '../dataSource/dataSources';
import DataSourceDetailCreate from '../dataSource/dataSourceDetailCreate';
import DataSourceDetailUpdate from '../dataSource/dataSourceDetailUpdate';

import DataSourceQueryContexts from '../dataSourceQueryContext/dataSourceQueryContexts';
import DataSourceQueryContextDetailCreate from '../dataSourceQueryContext/dataSourceQueryContextDetailCreate';
import DataSourceQueryContextDetailUpdate from '../dataSourceQueryContext/dataSourceQueryContextDetailUpdate';
import PushNotificationConfigurations from '../pushNotificationConfiguration/pushNotificationConfigurations';
import PushNotificationConfigurationDetailCreate from '../pushNotificationConfiguration/pushNotificationConfigurationDetailCreate';
import PushNotificationConfigurationDetailUpdate from '../pushNotificationConfiguration/pushNotificationConfigurationDetailUpdate';
import PushNotificationTasks from '../pushNotificationTask/pushNotificationTasks';
import PushNotificationTaskDetailCreate from '../pushNotificationTask/pushNotificationTaskDetailCreate';
import PushNotificationTaskDetailUpdate from '../pushNotificationTask/pushNotificationTaskDetailUpdate';
import EventTriggerConfigurations from '../eventTriggerConfiguration/eventTriggerConfigurations';
import EventTriggerConfigurationDetailCreate from '../eventTriggerConfiguration/eventTriggerConfigurationDetailCreate';
import EventTriggerConfigurationDetailUpdate from '../eventTriggerConfiguration/eventTriggerConfigurationDetailUpdate';
import VoucherClaims from '../voucherClaim/voucherClaims';
import VoucherClaimDetailCreate from '../voucherClaim/voucherClaimDetailCreate';

/*entityComponents*/

class App extends React.Component {
    componentDidMount() {
        console.warn('App.componentWillMount');

        let { search, actions } = this.props;

        if (search.token !== undefined) {
            console.warn('App.componentWillMount - verifyAccess');
            actions.verifyAccess(search.token);
        } else {
            // Fire immidietly
            console.warn('App.componentWillMount - hasAccess');
            actions.hasAccess();
        }

        let self = this;
        this.lastModified = new Date();

        // Cycling fire
        this.interval = setInterval(() => {
            actions.isAuth(self.lastModified);
        }, 15000);
    }

    componentWillReceiveProps(nextProps) {
        // Update last change date
        if (this.props.user && this.props.user.tokenExpireIn && nextProps.user && nextProps.user.tokenExpireIn) {
            // If token is renwed don't change time
            if (this.props.user.tokenExpireIn === nextProps.user.tokenExpireIn) this.lastModified = new Date();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    renderContent = () => {
        if (this.props.user.isAuth) {
            return (
                <div className="content">
                    <Switch>
                        <Route exact path="/" component={Dashboard} />
                        <Route exact path="/news" component={News} />
                        <Route exact path="/news/create" component={NewsDetailCreate} />
                        <Route exact path="/news/:id" component={NewsDetailUpdate} />
                        <Route exact path="/extra" component={Extra} />
                        <Route exact path="/betatester" component={BetaTester} />
                        <Route exact path="/betatester/create" component={BetaTesterDetailCreate} />
                        <Route exact path="/betatester/:id" component={BetaTesterDetailUpdate} />
                        <Route exact path="/chargingcode" component={ChargingCode} />
                        <Route exact path="/chargingcode/create" component={ChargingCodeDetailCreate} />
                        <Route exact path="/chargingcode/:id" component={ChargingCodeDetailUpdate} />
                        <Route exact path="/users" component={Users} />
                        <Route exact path="/users/create" component={UserDetailCreate} />
                        <Route exact path="/users/:id" component={UserDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.ROLES} component={Roles} />
                        <Route exact path={NAVIGATION_PATHS.ROLE_CREATE} component={RoleDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.ROLE_DETAIL} component={RoleDetailUpdate} />
                        <Route exact path="/competition" component={Competition} />
                        <Route exact path="/competition/create" component={CompetitionDetailCreate} />
                        <Route exact path="/competition/:id" component={CompetitionDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.REPORTS} component={Reports} />
                        <Route exact path={NAVIGATION_PATHS.REPORT_CREATE} component={ReportDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.REPORT_DETAIL} component={ReportDetailUpdate} />
                        <Route path={NAVIGATION_PATHS.REPORT_FILTER} component={ReportFilters} />
                        <Route exact path="/rating" component={Ratings} />
                        <Route exact path="/rating/:id" component={RatingDetailUpdate} />
                        <Route exact path="/ratingsettings" component={RatingSettings} />
                        <Route exact path="/ecomail" component={Ecomail} />
                        <Route exact path="/outagenotification" component={OutageNotifications} />
                        <Route exact path="/outagenotification/create" component={OutageNotificationDetailCreate} />
                        <Route exact path="/outagenotification/:id" component={OutageNotificationDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.REWARDS} component={Rewards} />
                        <Route exact path={NAVIGATION_PATHS.REWARD_CREATE} component={RewardDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.REWARD_DETAIL} component={RewardDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.TARGET_CAMPAIGNS} component={TargetCampaign} />
                        <Route exact path={NAVIGATION_PATHS.TARGET_CAMPAIGNS_CREATE} component={TargetCampaignDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.SIM_CARD_TYPES} component={SimCardTypes} />
                        <Route exact path={NAVIGATION_PATHS.SIM_CARD_TYPE_CREATE} component={SimCardTypeDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.SIM_CARD_TYPE_DETAIL} component={SimCardTypeDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS} component={AffiliateProgramPartners} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS_CREATE} component={AffiliateProgramPartnerDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS_DETAIL} component={AffiliateProgramPartnerDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS} component={AffiliateProgramRewards} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS_CREATE} component={AffiliateProgramRewardDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS_DETAIL} component={AffiliateProgramRewardDetailUpdate} />
                        <Route
                            exact
                            path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS}
                            component={AffiliateProgramSubscriberRewardClaims}
                        />
                        <Route
                            exact
                            path={NAVIGATION_PATHS.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_CREATE}
                            component={AffiliateProgramSubscriberRewardClaimDetailCreate}
                        />
                        <Route exact path={NAVIGATION_PATHS.GAME_CLANS} component={GameClans} />
                        <Route exact path={NAVIGATION_PATHS.TEXTS} component={Texts} />
                        <Route exact path={NAVIGATION_PATHS.TEXT_CREATE} component={TextDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.TEXT_DETAIL} component={TextDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.LINKS} component={Links} />
                        <Route exact path={NAVIGATION_PATHS.LINK_CREATE} component={LinkDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.LINK_DETAIL} component={LinkDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCES} component={DataSources} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCE_CREATE} component={DataSourceDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCE_DETAIL} component={DataSourceDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCEQUERYCONTEXTS} component={DataSourceQueryContexts} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCEQUERYCONTEXT_CREATE} component={DataSourceQueryContextDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.DATASOURCEQUERYCONTEXT_DETAIL} component={DataSourceQueryContextDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATIONS} component={PushNotificationConfigurations} />
                        <Route
                            exact
                            path={NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATION_CREATE}
                            component={PushNotificationConfigurationDetailCreate}
                        />
                        <Route
                            exact
                            path={NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATION_DETAIL}
                            component={PushNotificationConfigurationDetailUpdate}
                        />
                        <Route exact path={NAVIGATION_PATHS.PUSHNOTIFICATIONTASKS} component={PushNotificationTasks} />
                        <Route exact path={NAVIGATION_PATHS.PUSHNOTIFICATIONTASK_CREATE} component={PushNotificationTaskDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.PUSHNOTIFICATIONTASK_DETAIL} component={PushNotificationTaskDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.EVENTTRIGGERCONFIGURATIONS} component={EventTriggerConfigurations} />
                        <Route exact path={NAVIGATION_PATHS.EVENTTRIGGERCONFIGURATION_CREATE} component={EventTriggerConfigurationDetailCreate} />
                        <Route exact path={NAVIGATION_PATHS.EVENTTRIGGERCONFIGURATION_DETAIL} component={EventTriggerConfigurationDetailUpdate} />
                        <Route exact path={NAVIGATION_PATHS.VOUCHERCLAIMS} component={VoucherClaims} />
                        <Route exact path={NAVIGATION_PATHS.VOUCHERCLAIM_CREATE} component={VoucherClaimDetailCreate} />

                        /*entityRoutes*/
                    </Switch>
                </div>
            );
        }

        return '';
    };

    render() {
        return (
            <div>
                <LoadingSpinnerContainer />
                <div>
                    <SideBar path={this.props.path} open={this.props.open} />
                    <Header />
                    {this.renderContent()}
                </div>
            </div>
        );
    }
}

App.propTypes = {
    children: PropTypes.object.isRequired,
    open: PropTypes.bool,
    path: PropTypes.string,
    search: PropTypes.object,
    user: PropTypes.shape({
        tokenExpireIn: PropTypes.string,
        isAuth: PropTypes.bool,
    }),
    actions: PropTypes.shape({
        isAuth: PropTypes.func.isRequired,
        verifyAccess: PropTypes.func.isRequired,
        hasAccess: PropTypes.func.isRequired,
    }),
};

const mapStateToProps = (state, ownProps) => {
    return {
        open: state.sideBar.open,
        user: state.user,
        path: ownProps.location.pathname,
        search: ownProps.location.search,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(userActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
