import {
    EnumDescription,
    getEnumItemByValue,
    VOUCHER_CLAIM_SOURCE,
    VOUCHER_CLAIM_SOURCE_ENUM,
    VOUCHER_CLAIM_VARIANT,
    VOUCHER_CLAIM_VARIANT_ENUM,
} from '../common/enums';

export default class VoucherClaimCreateMutation {
    msisdn: string;
    note: string;
    variant: number;
    variantEnumDescription: EnumDescription | undefined;
    source: number;
    sourceEnumDescription: EnumDescription | undefined;

    constructor(properties: Omit<VoucherClaimCreateMutation, 'variantEnumDescription' | 'sourceEnumDescription'>) {
        this.msisdn = '';
        this.note = '';
        this.variant = VOUCHER_CLAIM_VARIANT_ENUM.Fifty;
        this.source = VOUCHER_CLAIM_SOURCE_ENUM.CustomerCare;

        Object.assign(this, properties);

        this.variantEnumDescription = getEnumItemByValue(properties.variant, VOUCHER_CLAIM_VARIANT);
        this.sourceEnumDescription = getEnumItemByValue(properties.source, VOUCHER_CLAIM_SOURCE);
    }
}
