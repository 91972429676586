import * as types from './actionTypes';
import { NAVIGATION_PATHS, NAVIGATION_PATHS_TYPE } from '../common/constants';

export const navigateTo = (route: string) => {
    return { type: types.NAVIGATE_TO, path: route };
};

export const navigateToDetail = (route: NAVIGATION_PATHS_TYPE, id: number) => {
    const path = NAVIGATION_PATHS[route];
    const pathWihtId = path.replace(':id', id.toString());

    return { type: types.NAVIGATE_TO, path: pathWihtId };
};

export const navigateToNews = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.NEWS };
};

export const navigateToRatings = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.RATING };
};

export const navigateToCompetitions = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.COMPETITION };
};

export const navigateToBetatester = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.BETA_TESTER };
};

export const navigateToUsers = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.USERS };
};

export const navigateToSingIn = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SIGN_IN };
};

export const navigateToChargingCode = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.CHARGING_CODE };
};

export const navigateToReports = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.REPORTS };
};

export const navigateToOutageNotifications = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.OUTAGE_NOTIFICATION };
};

export const navigateToTargetCampaigns = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.TARGET_CAMPAIGNS };
};

export const navigateToRewards = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.REWARDS };
};

export const navigateToSimCardTypes = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.SIM_CARD_TYPES };
};

export const navigateToAffiliateProgramRewards = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS };
};

export const navigateToAffiliateProgramPartners = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS };
};

export const navigateToAffiliateProgramSubscriberRewardClaims = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS };
};

export const navigateToRoles = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.ROLES };
};

export const navigateToGameClans = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.GAME_CLANS };
};

export const navigateToTexts = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.TEXTS };
};

export const navigateToLinks = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.LINKS };
};

export const navigateToDataSources = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.DATASOURCES };
};

export const navigateToDataSourceQueryContexts = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.DATASOURCEQUERYCONTEXTS };
};
export const navigateToPushNotificationConfigurations = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATIONS };
};
export const navigateToPushNotificationTasks = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.PUSHNOTIFICATIONTASKS };
};
export const navigateToEventTriggerConfigurations = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.EVENTTRIGGERCONFIGURATIONS };
};
export const navigateToVoucherClaims = () => {
    return { type: types.NAVIGATE_TO, path: NAVIGATION_PATHS.VOUCHERCLAIMS };
};

/*navigateToEntity*/
