import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import {
    DialogTitle,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    DialogActions,
    Button,
    DialogProps,
    SelectChangeEvent,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';

const LoadingSpinner: React.FunctionComponent = () => {
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const handleMaxWidthChange = (event: SelectChangeEvent<typeof maxWidth>) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value
        );
    };

    const handleFullWidthChange = (event: SwitchChangeEvent) => {
        setFullWidth(event.target.value);
    };

    return (
        <Backdrop sx={(theme) => ({ color: '#0000ff', zIndex: theme.zIndex.drawer + 10000 })} open>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};

export default LoadingSpinner;
