export const NEWS_TYPES = [
    { text: 'otevření webové stránky', value: 0 },
    { text: 'navigace na stránku v aplikaci', value: 1 },
    { text: 'objednávka služby', value: 2 },
    { text: 'SMS objednávka', value: 3 },
    { text: 'detail služby z banneru', value: 4 },
    { text: 'detail služby z detailu banneru', value: 5 },
    { text: 'detail Jen pro mě z banneru', value: 6 },
    { text: 'detail Jen pro mě z detailu banneru', value: 7 },
];

export const NEWS_SECTION_TYPES = [
    { text: 'hlavní stránka', value: 2 },
    { text: 'moje rodina', value: 4 },
    { text: 'hlavní stránka + moje rodina', value: 6 },
];

export const RATING_TRIGGER_TYPES = [
    { text: 'po objednávce', value: 0 },
    { text: 'po dobití kreditu', value: 1 },
    { text: 'po souteži', value: 2 },
    { text: 'po odejití z Extra', value: 3 },
    { text: 'po odejití ze Suri game', value: 4 },
];

export const COMPETITION_TARGET_CUSTOMER_TYPES = [
    { text: 'všichni zákazníci', value: 0 },
    { text: 'pouze pro VIP', value: 1 },
];

export enum PLATFORMA_TYPES_ENUM {
    ALL = 0,
    ANDROID = 1,
    IOS = 2,
    SELFCARE = 3,
    MOBILE_APP = 4,
}

export const PLATFORMA_TYPES = [
    { text: 'všechny platformy', value: PLATFORMA_TYPES_ENUM.ALL },
    { text: 'Android', value: PLATFORMA_TYPES_ENUM.ANDROID },
    { text: 'iOS', value: PLATFORMA_TYPES_ENUM.IOS },
    { text: 'Selfcare', value: PLATFORMA_TYPES_ENUM.SELFCARE },
    { text: 'Mobilní aplikace', value: PLATFORMA_TYPES_ENUM.MOBILE_APP },
];

export const JOB_PROCESSOR_TYPES = [
    { text: 'žádná', value: 0 },
    { text: 'výpočet VIP statusu', value: 1 },
    { text: 'import historického bunosového kreditu', value: 2 },
];

export const JOB_STATUS_TYPES = [
    { text: 'žádná', value: 0 },
    { text: 'výpočet VIP statusu', value: 1 },
    { text: 'import historického bunosového kreditu', value: 2 },
];

export const RATING_VARIANT_WITHOUT_STARS_ENUM_VALUE = 1;
export const RATING_VARIANT_DEFAULT_ENUM_VALUE = 0;

export const RATING_VARIANT_TYPES = [
    { text: 'výchozí', value: RATING_VARIANT_DEFAULT_ENUM_VALUE },
    { text: 'bez hvězdiček', value: RATING_VARIANT_WITHOUT_STARS_ENUM_VALUE },
];

export enum SOURCE_OF_SUBSCRIBERS_TYPES_ENUM {
    ALL = 0,
    O2_VOPE = 1,
    CMT = 2,
    GAME = 3,
    DIGI_CLUB = 4,
    HVC = 5,
    FAMILYMEMBER = 6,
    CLUBCARDMEMBER = 7,
    BIRTHDAY = 8,
    AFFILIATE_PROGRAM = 9,
    AFTER_REGISTRATION = 10,
    ELM = 11,
    DATA_SOURCE = 12,
}

export const SOURCE_OF_SUBSCRIBERS_TYPES = [
    { text: 'Pro všechny', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.ALL },
    { text: 'O2 - VOPE', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.O2_VOPE },
    { text: 'CMT', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.CMT },
    { text: 'Hra', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.GAME },
    { text: 'DIGI club', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.DIGI_CLUB },
    { text: 'Hvc', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.HVC },
    { text: 'Family member', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.FAMILYMEMBER },
    { text: 'Clubcard memmber', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.CLUBCARDMEMBER },
    { text: 'Birthday', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.BIRTHDAY },
    { text: 'Partnerský program', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.AFFILIATE_PROGRAM },
    { text: 'Po registraci', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.AFTER_REGISTRATION },
    { text: 'ELM', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.ELM },
    { text: 'Datový zdroj', value: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.DATA_SOURCE },
];

export const REWARD_TYPES = [
    { text: 'Produkt', value: 0 },
    /* { text: 'Kredit', value: 1 }, */
];

export enum REWARD_VARIANT_ENUM {
    STANDARD = 0,
    FOLLOWING = 1,
}

export const REWARD_VARIANT = [
    { text: 'Standardní', value: REWARD_VARIANT_ENUM.STANDARD },
    { text: 'Navazující', value: REWARD_VARIANT_ENUM.FOLLOWING },
];

export const TARGETED_CAMPAIGN_CONDITION_TYPE = [
    { text: 'Cilit na skupinu', value: 0 },
    { text: 'Vyloučit skupinu z cílení', value: 1 },
];

export const TARGETED_GROUP_SUFFIX_TYPE = [{ text: 'Denní', value: 1 }];

export const OUTAGE_NOTIFICATION_FILTER_TYPE = [
    { text: 'Žádný', value: 0 },
    { text: 'Verze mobilní aplikace', value: 1 },
    { text: 'Skupina uživatelů', value: 2 },
];

export enum TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM {
    CMT = 1 << 0,
    HVC = 1 << 1,
    DIGICLUB = 1 << 2,
    FAMILYMEMBER = 1 << 3,
    CLUBCARDMEMBER = 1 << 4,
    BIRTHDAY = 1 << 5,
    GAME = 1 << 6,
    MARKETING_ACTION_USAGES = 1 << 7,
    REWARD_USAGES = 1 << 8,
    AFFILIATE_PROGRAM = 1 << 9,
    AFTER_REGISTRATION = 1 << 10,
    ELM = 1 << 11,
    PRODUCT_STATE_CHANGE = 1 << 12,
}

export const TARGETED_CAMPAIGN_DATA_SOURCE_TYPE = [
    { text: 'CMT', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.CMT },
    { text: 'Hvc', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.HVC },
    { text: 'DigiClub', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.DIGICLUB },
    { text: 'FamilyMember', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.FAMILYMEMBER },
    { text: 'ClubcardMember', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.CLUBCARDMEMBER },
    { text: 'Birthday', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.BIRTHDAY },
    { text: 'MarketingAction', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.MARKETING_ACTION_USAGES },
    { text: 'Reward', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.REWARD_USAGES },
    { text: 'AffiliateProgram', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.AFFILIATE_PROGRAM },
    { text: 'AfterRegistration', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.AFTER_REGISTRATION },
    { text: 'ELM', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.ELM },
    { text: 'Změny stavů balíčku', value: TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.PRODUCT_STATE_CHANGE },
];

export enum NEWS_BANNER_VIEW_TYPES_ENUM {
    DEFAULT = 0,
    TEXT_OVER_BANNER = 1,
}

export const NEWS_BANNER_VIEW_TYPES = [
    { text: 'Výchozí', value: NEWS_BANNER_VIEW_TYPES_ENUM.DEFAULT },
    { text: 'Text přes banner', value: NEWS_BANNER_VIEW_TYPES_ENUM.TEXT_OVER_BANNER },
];

/*
        Equal = 0,

        NotEqual = 1,

        GreaterThan = 2,

        GreaterThanOrEqual = 3,

        LessThan = 4,

        LessThanOrEqual = 5
*/
export const OUTAGE_NOTIFICATION_CONDITION_TYPE = [
    { text: 'Rovná se', value: 0 },
    { text: 'Nerovná se', value: 1 },
    { text: 'Větší než', value: 2 },
    { text: 'Větší nebo rovno', value: 3 },
    { text: 'Menší než', value: 4 },
    { text: 'Menší nebo rovno', value: 5 },
];

export enum CAMPRO_MODULE_ENUM {
    NONE = 0,
    NEWS = 1 << 0,
    BETA_TESTERS = 1 << 1,
    USERS = 1 << 2,
    ROLES = 1 << 3,
    COMPETITIONS = 1 << 4,
    EXTRA = 1 << 5,
    CHARGINGCODES = 1 << 6,
    REPORTS = 1 << 7,
    RATING = 1 << 8,
    RATING_SETTINGS = 1 << 9,
    ECOMAIL = 1 << 10,
    OUTAGE_NOTIFICATIONS = 1 << 11,
    FOR_ME = 1 << 12,
    CMT = 1 << 13,
    SIMCARD_TYPES = 1 << 14,
    AFFILIATE_PROGRAM_REWARDS = 1 << 15,
    AFFILIATE_PROGRAM_PARTNERS = 1 << 16,
    AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS = 1 << 17,
    GAME_CLANS = 1 << 18,
    TEXTS = 1 << 19,
    LINKS = 1 << 20,
    DATASOURCES = 1 << 21,
    DATASOURCEQUERYCONTEXTS = 1 << 22,
    PUSHNOTIFICATIONCONFIGURATIONS = 1 << 23,
    PUSHNOTIFICATIONTASKS = 1 << 24,
    EVENTTRIGGERCONFIGURATIONS = 1 << 25,
    VOUCHERCLAIMS = 1 << 26,
}

export const CAMPRO_MODULES = [
    { text: 'Žádný', value: -1 },
    { text: 'Zprávy', value: CAMPRO_MODULE_ENUM.NEWS },
    { text: 'Beta testeři', value: CAMPRO_MODULE_ENUM.BETA_TESTERS },
    { text: 'Uživatelé', value: CAMPRO_MODULE_ENUM.USERS },
    { text: 'Role', value: CAMPRO_MODULE_ENUM.ROLES },
    { text: 'Soutěže', value: CAMPRO_MODULE_ENUM.COMPETITIONS },
    { text: 'Extra', value: CAMPRO_MODULE_ENUM.EXTRA },
    { text: 'Charging codes', value: CAMPRO_MODULE_ENUM.CHARGINGCODES },
    { text: 'Reporty', value: CAMPRO_MODULE_ENUM.REPORTS },
    { text: 'Hodnocení', value: CAMPRO_MODULE_ENUM.RATING },
    { text: 'Nastavení hodnocení', value: CAMPRO_MODULE_ENUM.RATING_SETTINGS },
    { text: 'Ecomail', value: CAMPRO_MODULE_ENUM.ECOMAIL },
    { text: 'Hlášení výpadků', value: CAMPRO_MODULE_ENUM.OUTAGE_NOTIFICATIONS },
    { text: 'Jen pro mě', value: CAMPRO_MODULE_ENUM.FOR_ME },
    { text: 'CMT', value: CAMPRO_MODULE_ENUM.CMT },
    { text: 'Typy simkaret', value: CAMPRO_MODULE_ENUM.SIMCARD_TYPES },
    { text: 'Partnerský program - partneři', value: CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_PARTNERS },
    { text: 'Partnerský program - odměny', value: CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_REWARDS },
    { text: 'Partnerský program - nároky', value: CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS },
    { text: 'Herní klany', value: CAMPRO_MODULE_ENUM.GAME_CLANS },
    { text: 'Texty', value: CAMPRO_MODULE_ENUM.TEXTS },
    { text: 'Linky', value: CAMPRO_MODULE_ENUM.LINKS },
    { text: 'Datové zdroje', value: CAMPRO_MODULE_ENUM.DATASOURCES },
    { text: 'Datové zdroje - kontexty', value: CAMPRO_MODULE_ENUM.DATASOURCEQUERYCONTEXTS },
    { text: 'Push notifikcae - definice', value: CAMPRO_MODULE_ENUM.PUSHNOTIFICATIONCONFIGURATIONS },
    { text: 'Push notifikcae - tasky', value: CAMPRO_MODULE_ENUM.PUSHNOTIFICATIONTASKS },
    { text: 'Notifikace při události', value: CAMPRO_MODULE_ENUM.EVENTTRIGGERCONFIGURATIONS },
    { text: 'Poukazy - nároky', value: CAMPRO_MODULE_ENUM.VOUCHERCLAIMS },

    /*entityModul*/
];

export enum LANGUAGE_ENUM {
    CZ = 1,
}

export const LANGUAGE_TYPES = [{ text: 'CZ', value: LANGUAGE_ENUM.CZ }];

export enum LINK_TYPE_ENUM {
    YOUTUBE_VIDEO = 1,
    VIDEO = 2,
}

export const LINK_TYPE_TYPES = [
    { text: 'Youtube video', value: LINK_TYPE_ENUM.YOUTUBE_VIDEO },
    { text: 'Video', value: LINK_TYPE_ENUM.VIDEO },
];

export enum DATASOURCE_QUERY_TYPE_ENUM {
    OneRecord = 1,
    ListOfRecords = 2,
}

export const DATASOURCE_QUERY_TYPE_TYPES = [
    { text: 'Jeden záznam', value: DATASOURCE_QUERY_TYPE_ENUM.OneRecord },
    { text: 'Seznam', value: DATASOURCE_QUERY_TYPE_ENUM.ListOfRecords },
];

export enum DATASOURCE_EXECUTION_POLICY_ENUM {
    FullDataSource = 1,
    OnlyNewComparedToThePreviousOne = 2,
}

export const DATASOURCE_EXECUTION_POLICY_TYPES = [
    { text: 'Kompletní datový zdroj', value: DATASOURCE_EXECUTION_POLICY_ENUM.FullDataSource },
    { text: 'Jen nové', value: DATASOURCE_EXECUTION_POLICY_ENUM.OnlyNewComparedToThePreviousOne },
];

export enum EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM {
    NewVoucherClaim = 1,
    /*  AfterRegistration = 2,
    AfterLogin = 3, */
    FamilyMemberAdded = 4,
    FamilyMemberRemoved = 5,
    GameClanMemberAdded = 6,
    GameClanMemberRemoved = 7,
    AssetActivated = 8,
    AssetDeactivated = 9,
    AssetRenewal = 10,
    AssetWillBeSuspended = 11,
    AssetSuspended = 12,
}

export const EVENT_TRIGGER_CONFIGURATION_SOURCE_TYPES = [
    { text: 'Nový nárok na poukazy', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.NewVoucherClaim },

    /*   { text: 'Po registraci', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AfterRegistration },
    { text: 'Po přihlášení', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AfterLogin }, */

    { text: 'Přidání nového člena rodiny', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.FamilyMemberAdded },
    { text: 'Odebrání član rodiny', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.FamilyMemberRemoved },

    { text: 'Přidání nového člena klanu', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.GameClanMemberAdded },
    { text: 'Odebrání član klanu', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.GameClanMemberRemoved },

    { text: 'Aktivace balíčku', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AssetActivated },
    { text: 'Deaktivace balíčku', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AssetDeactivated },
    { text: 'Obnova balíčku', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AssetRenewal },
    { text: 'Balíček půjde do suspendu', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AssetWillBeSuspended },
    { text: 'Balíček přešel do suspendu', value: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.AssetSuspended },
];

export enum DATASOURCE_TYPE_ENUM {
    Query = 1,
    O2Rewards = 2,
}

export const DATASOURCE_TYPE_TYPES = [
    { text: 'Query', value: DATASOURCE_TYPE_ENUM.Query },
    { text: 'O2Rewards', value: DATASOURCE_TYPE_ENUM.O2Rewards },
];

export enum PUSH_NOTIFICATION_CHANNEL_TYPE_ENUM {
    Default = 1,
    Marketing = 2,
}

export enum VOUCHER_CLAIM_STATUS_ENUM {
    NotUsed = 0,
    Active = 1,
    Used = 2,
    Invalid = 3,
}

export const VOUCHER_CLAIM_STATUS = [
    { text: 'Nepoužitý', value: VOUCHER_CLAIM_STATUS_ENUM.NotUsed },
    { text: 'Aktivní', value: VOUCHER_CLAIM_STATUS_ENUM.Active },
    { text: 'Použitý', value: VOUCHER_CLAIM_STATUS_ENUM.Used },
    { text: 'Neplatný/Propadlý', value: VOUCHER_CLAIM_STATUS_ENUM.Invalid },
];

export enum VOUCHER_CLAIM_SOURCE_ENUM {
    Suri = 4,
    CustomerCare = 6,
    Competition = 8,
}

export const VOUCHER_CLAIM_SOURCE = [
    { text: 'Suri', value: VOUCHER_CLAIM_SOURCE_ENUM.Suri },
    { text: 'Zákaznické centrum', value: VOUCHER_CLAIM_SOURCE_ENUM.CustomerCare },
    { text: 'Soutěže', value: VOUCHER_CLAIM_SOURCE_ENUM.Competition },
];

export enum VOUCHER_CLAIM_VARIANT_ENUM {
    Fifty = 1,
}

export const VOUCHER_CLAIM_VARIANT = [{ text: '50 Kč', value: VOUCHER_CLAIM_VARIANT_ENUM.Fifty }];

export const PUSH_NOTIFICATION_CHANNEL_TYPE_TYPES = [
    { text: 'Základní informace', value: PUSH_NOTIFICATION_CHANNEL_TYPE_ENUM.Default },
    { text: 'Výhodné nabídky', value: PUSH_NOTIFICATION_CHANNEL_TYPE_ENUM.Marketing },
];

export enum TARGETED_CAMPAIGN_CODES_ENUM {
    NEO18_1KC_CC = 'NEO18_1KC_CC',
}

export const TARGETED_CAMPAIGN_CODES = [{ text: 'NEO18 1Kč CC', value: TARGETED_CAMPAIGN_CODES_ENUM.NEO18_1KC_CC }];

export const getEnumItemByValue = (enumValue: number, enumSource: EnumDescription[]): EnumDescription | undefined => {
    if (!Array.isArray(enumSource)) {
        throw Error('Enum source must be Array!');
    }

    return enumSource.find((enumItem) => {
        return enumItem.value === enumValue;
    });
};

export const getEnumItemByStringValue = (enumValue: string, enumSource: EnumStringDescription[]): EnumStringDescription | undefined => {
    if (!Array.isArray(enumSource)) {
        throw Error('Enum source must be Array!');
    }

    return enumSource.find((enumItem) => {
        return enumItem.value === enumValue;
    });
};

export const getEnumItemsByFlaggedValue = (enumFlaggedValue: number, enumSource: EnumDescription[]): EnumDescription[] => {
    if (!Array.isArray(enumSource)) {
        throw Error('Enum source must be Array!');
    }

    return enumSource.filter((item) => {
        return (item.value & enumFlaggedValue) === item.value;
    });
};

export const getFlaggeValueFormEnumItems = (enumValues: EnumDescription[]): number | null => {
    if (!Array.isArray(enumValues)) {
        throw Error('Enum values must be Array!');
    }

    return enumValues.length > 0 ? enumValues.reduce((total, item, i) => total + item.value, 0) : null;
};

export const isSelectedEnumFlagg = (enumValues: EnumDescription[], enumFlagg: number): boolean => {
    if (!enumValues || !(enumValues.length > 0)) {
        return false;
    }

    return enumValues.some((item) => {
        const bit = item.value & enumFlagg;

        return bit === item.value;
    });
};

export type EnumDescription = {
    text: string;
    value: number;
};

export type EnumStringDescription = {
    text: string;
    value: string;
};
