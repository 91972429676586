import * as types from './dataTransformationTypes';
import newsDataTransformationAfterFetch from './newsDataTransformationAfterFetch';
import competitionDataTransformationAfterFetch from './competitionDataTransformationAfterFetch';
import ratingDataTransformationAfterFetch from './ratingDataTransformationAfterFetch';
import outageNotificationDataTransformationAfterFetch from './outageNotificationDataTransformationAfterFetch';
import ratingSettingsDataTransformationAfterFetch from './ratingSettingsDataTransformationAfterFetch';
import rewardDataTransformationAfterFetch from './rewardDataTransformationAfterFetch';
import targetCampaignDataTransformationAfterFetch from './targetCampaignDataTransformationAfterFetch';
import simCardTypeDataTransformationAfterFetch from './simCardTypeDataTransformationAfterFetch';
import affiliateProgramPartnerDataTransformationAfterFetch from './affiliateProgramPartnerDataTransformationAfterFetch';
import affiliateProgramRewardDataTransformationAfterFetch from './affiliateProgramRewardDataTransformationAfterFetch';
import affiliateProgramSubscriberRewardClaimDataTransformationAfterFetch from './affiliateProgramSubscriberRewardClaimDataTransformationAfterFetch';
import roleDataTransformationAfterFetch from './roleDataTransformationAfterFetch';
import gameClanDataTransformationAfterFetch from './gameClanDataTransformationAfterFetch';
import textDataTransformationAfterFetch from './textDataTransformationAfterFetch';
import linkDataTransformationAfterFetch from './linkDataTransformationAfterFetch';
import datasourceDataTransformationAfterFetch from './dataSourceDataTransformationAfterFetch';
import dataSourceQuerycontextDataTransformationAfterFetch from './dataSourceQueryContextDataTransformationAfterFetch';
import pushnotificationconfigurationDataTransformationAfterFetch from './pushNotificationConfigurationDataTransformationAfterFetch';
import pushnotificationtaskDataTransformationAfterFetch from './pushNotificationTaskDataTransformationAfterFetch';
import eventtriggerconfigurationDataTransformationAfterFetch from './eventTriggerConfigurationDataTransformationAfterFetch';
import voucherclaimDataTransformationAfterFetch from './voucherClaimDataTransformationAfterFetch';

/*importTransformation*/

const dataTransformations = (dataTransformationName) => (data) => {
    switch (dataTransformationName) {
        case types.NEWS: {
            return newsDataTransformationAfterFetch(data);
        }

        case types.COMPETITION: {
            return competitionDataTransformationAfterFetch(data);
        }

        case types.RATING: {
            return ratingDataTransformationAfterFetch(data);
        }

        case types.OUTAGE_NOTIFICATION: {
            return outageNotificationDataTransformationAfterFetch(data);
        }

        case types.RATING_SETTINS: {
            return ratingSettingsDataTransformationAfterFetch(data);
        }

        case types.REWARD: {
            return rewardDataTransformationAfterFetch(data);
        }

        case types.TARGET_CAMPAIGN: {
            return targetCampaignDataTransformationAfterFetch(data);
        }

        case types.SIM_CARD_TYPE: {
            return simCardTypeDataTransformationAfterFetch(data);
        }

        case types.AFFILIATE_PROGRAM_PARTNER: {
            return affiliateProgramPartnerDataTransformationAfterFetch(data);
        }

        case types.AFFILIATE_PROGRAM_REWARD: {
            return affiliateProgramRewardDataTransformationAfterFetch(data);
        }

        case types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS: {
            return affiliateProgramSubscriberRewardClaimDataTransformationAfterFetch(data);
        }

        case types.ROLE: {
            return roleDataTransformationAfterFetch(data);
        }

        case types.GAME_CLAN: {
            return gameClanDataTransformationAfterFetch(data);
        }

        case types.TEXT: {
            return textDataTransformationAfterFetch(data);
        }

        case types.LINK: {
            return linkDataTransformationAfterFetch(data);
        }

        case types.DATASOURCE: {
            return datasourceDataTransformationAfterFetch(data);
        }

        case types.DATASOURCE: {
            return datasourceDataTransformationAfterFetch(data);
        }

        case types.DATASOURCE: {
            return datasourceDataTransformationAfterFetch(data);
        }

        case types.DATASOURCE: {
            return datasourceDataTransformationAfterFetch(data);
        }

        case types.DATASOURCE: {
            return datasourceDataTransformationAfterFetch(data);
        }

        case types.DATASOURCEQUERYCONTEXT: {
            return dataSourceQuerycontextDataTransformationAfterFetch(data);
        }

        case types.PUSHNOTIFICATIONCONFIGURATION: {
            return pushnotificationconfigurationDataTransformationAfterFetch(data);
        }

        case types.PUSHNOTIFICATIONTASK: {
            return pushnotificationtaskDataTransformationAfterFetch(data);
        }

        case types.EVENTTRIGGERCONFIGURATION: {
            return eventtriggerconfigurationDataTransformationAfterFetch(data);
        }

        case types.VOUCHERCLAIM: {
            return voucherclaimDataTransformationAfterFetch(data);
        }

        /*caseTransformation*/
        default:
            throw new Error(`Unsupported data transformation type - ${dataTransformationName}`);
    }
};

export default dataTransformations;
