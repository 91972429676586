import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import ResponseStatus from '../utils/responseStatus';
import { showLoadingSpinner, hideLoadingSpinner } from './loadingSpinnerActions';
import { addHrefWithFileToDownload } from '../utils/commonFunctions';
import TargetedCampaign from '../entities/targetedCampaign';
import targetCampaignDataTransformationBeforeSave from '../data-transformations/targetCampaignDataTransformationBeforeSave';
import { navigateToTargetCampaigns } from './navigationActions';
import { alertAdd } from './alertActions';

const downloadReport = (reportId: string, dispatch: Dispatch<any>, getState: any) => {
    dispatch(beginAjaxCall());

    if (reportId) {
        dispatch(showLoadingSpinner());

        const { table } = getState();
        const { actionUrl } = table;

        axios
            .get(`${actionUrl}/xlsx/${reportId}`, {
                responseType: 'blob',
            })
            .then((response) => {
                if (ResponseStatus.IsOK(response.status)) {
                    const dateString = new Date().toLocaleDateString();
                    addHrefWithFileToDownload(response.data, `${reportId}_${dateString}.xlsx`);

                    dispatch(hideLoadingSpinner());
                } else {
                    dispatch(hideLoadingSpinner());
                    dispatch(ajaxCallError());
                }
            })
            .catch((error) => {
                dispatch(hideLoadingSpinner());
                dispatch(ajaxCallError());
                throw error;
            });
    }
};

export const downloadReportById = (id: string) => (dispatch: Dispatch<any>, getState: any) => {
    downloadReport(id, dispatch, getState);
};

export const createTargetCampaign = (data: TargetedCampaign) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());
    dispatch(showLoadingSpinner());

    const transformedData = targetCampaignDataTransformationBeforeSave(data);

    axios
        .post(`targetcampaign`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.TARGET_CAMPAIGN_ADD,
                });

                dispatch(hideLoadingSpinner());
                dispatch(navigateToTargetCampaigns());
                dispatch(alertAdd('RecordWasCreated', 'info'));
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(hideLoadingSpinner());
            dispatch(ajaxCallError());
            throw error;
        });
};
