import TargetedCampaign from '../entities/targetedCampaign';

const targetCampaignDataTransformationBeforeSave = (targetedCampaign: TargetedCampaign) => {
    const transformedData = {
        ...targetedCampaign,
        campaignCode: targetedCampaign.campaignCodeDescription?.value,
    } as { [key: string]: any };

    return transformedData;
};

export default targetCampaignDataTransformationBeforeSave;