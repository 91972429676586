import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getState } from '../../reducers/voucherClaimReducer';
import { setSearchMsisdn, resetSearchMsisdn, filterByMsisdn } from '../../actions/voucherClaimActions';
import SubmitButton from '../common/SubmitButton';
import TranslatedTextBox from '../common/TranslatedTextBox';
import Translation from '../common/Translation';

class VoucherClaimsSearchBox extends React.Component {
    state = {
        value: this.props.searchMsisdn,
    };

    setFilter = (value) => {
        this.props.filterByMsisdnAction(value);
    };

    clear = () => {
        this.setState({ value: '' });
        this.setFilter('');
        this.props.resetSearchMsisdnAction();
    };

    handleSearch = (e) => {
        e.preventDefault();
        const { value } = this.state;

        this.setFilter(value);
        this.props.setSearchMsisdnAction(value);
    };

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    };

    render() {
        return (
            <div className="search">
                <TranslatedTextBox name="search" value={this.state.value} onChange={this.handleChange} placeholder="Msisdn" />
                &nbsp;
                {this.state.value && <span className={`icon icon-cancel`} onClick={this.clear} />}
                <SubmitButton onClick={this.handleSearch}>
                    <Translation tKey="Search" />
                </SubmitButton>
            </div>
        );
    }
}

VoucherClaimsSearchBox.propTypes = {
    actions: PropTypes.object,
    searchMsisdn: PropTypes.string,
};

const mapDispatchToProps = (dispatch) => {
    return {
        filterByMsisdnAction: bindActionCreators(filterByMsisdn, dispatch),
        setSearchMsisdnAction: bindActionCreators(setSearchMsisdn, dispatch),
        resetSearchMsisdnAction: bindActionCreators(resetSearchMsisdn, dispatch),
    };
};

const mapStateToProps = (state) => {
    const searchMsisdn = getState(state).searchMsisdn;

    return {
        searchMsisdn,
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VoucherClaimsSearchBox);
