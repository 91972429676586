import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/stringCellEx';
import DateCell from '../common/formatters/DateCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { TARGET_CAMPAIGN } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import { downloadReportById } from '../../actions/targetCampaignActions';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import TargetedCampaign from '../../entities/targetedCampaign';
import { nameofFactory } from '../../helpers/nameOfFactory';
import ActionButton from '../common/ActionButton';
import TargetCampaignSearchBox from './targetCampaignSearchBox';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TextField } from '@mui/material';
import DateTimeCell from '../common/formatters/DateTimeCell';

const nameof = nameofFactory<TargetedCampaign>();

const defaultRatingTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'targetcampaign';

interface TargetCampaignProp extends TargetCampaignDispatchProps {}

interface TargetCampaignDispatchProps {
    t: any;
    navigateTo: typeof navigateTo;
    downloadReportById: (id: string) => (dispatch: Dispatch<TargetCampaignDispatchProps>, getState: any) => void;
}

const TargetCampaign = (props: TargetCampaignProp) => {
    const { t, downloadReportById, navigateTo } = props;
    const [visible, setVisible] = React.useState(false);

    let table: any;

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.TARGET_CAMPAIGNS_CREATE);
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="TargetCampaigns" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <div className="actions">
                            <ActionButton text="Add" onClick={onNew} icon="plus" />
                            <ActionButton text="Remove" onClick={onRemove} icon="delete" selection="one"  />
                        </div>
                        <div className="filter" />
                        <TargetCampaignSearchBox />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'targetcampaign'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={TARGET_CAMPAIGN}
                            name="TargetCampaignTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultRatingTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('campaignCode')}
                                width="300"
                                cell={(props) => <StringCell {...props} textAlign={TEXT_HORIZONTAL_ALIGN.LEFT} />}
                                title={t('CampaignCode')}
                            />
                            <Column
                                field={nameof('msisdn')}
                                width="300"
                                cell={(props) => <StringCell {...props} textAlign={TEXT_HORIZONTAL_ALIGN.LEFT} />}
                                title={t('Msisdn')}
                            />
                            <Column field={nameof('dateOfCreate')} width="300" cell={(props) => <DateTimeCell {...props} />} title={t('DateOfCreate')} />
                            {/* <Column
                                field={nameof('dateOfModified')}
                                width="300"
                                cell={(props) => <DateTimeCell {...props} />}
                                title={t('DateOfModified')}
                            />     */}                      
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<TargetCampaignDispatchProps>) => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        downloadReportById: (id: string) => dispatch(downloadReportById(id)),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(TargetCampaign);
