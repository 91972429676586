import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { translate } from 'react-i18next';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import { DialogContent, DialogContentText, TextField, DialogActions } from '@mui/material';

import Table from '../common/Table';
import StringCell from '../common/formatters/StringCell';
import Translation from '../common/Translation';
import EnumCell from '../common/formatters/EnumCelll';
import Alerts from '../common/Alerts';
import ActionButton from '../common/ActionButton';
import { navigateTo } from '../../actions/navigationActions';
import { TEXT_HORIZONTAL_ALIGN, NAVIGATION_PATHS } from '../../common/constants';
import { nameofFactory } from '../../helpers/nameOfFactory';
import VoucherClaim from '../../entities/voucherClaim';
import { VOUCHERCLAIM } from '../../data-transformations/dataTransformationTypes';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';
import * as enums from '../../common/enums';
import VoucherClaimsSearchBox from './voucherClaimsSearchBox';
import DateTimeCell from '../common/formatters/DateTimeCell';
import { setAsUnused } from '../../actions/voucherClaimActions';
import ToggableActionButton from '../common/ToggableActionButton';
import { VOUCHER_CLAIM_STATUS_ENUM } from '../../common/enums';

const nameof = nameofFactory<VoucherClaim>();

const actionUrl = 'voucherclaims';

interface VoucherClaimProps extends BaseListProps, VoucherClaimDispatchProps {}

interface VoucherClaimDispatchProps extends BaseListDispatchProps {
    setAsUnusedAction: typeof setAsUnused;
}

type StatusFilterName = 'notUsed' | 'used';
type FilterMap = { [FILTER in StatusFilterName]: boolean };

const VoucherClaims = ({ t, navigateTo, setAsUnusedAction }: VoucherClaimProps) => {
    let table: any;
    const [visible, setVisible] = React.useState(false);
    const [filters, setFilter] = React.useState<Partial<FilterMap>>({ notUsed: false, used: false });

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.VOUCHERCLAIM_CREATE);
    };

    const onSetAsUnused = () => {
        setVisible(true);
    };

    const toggleDialog = (value?: string) => {
        setVisible(!visible);

        if (value) {
            setAsUnusedAction(value);
        }
    };

    const onRemoveNotUsedFilter = () => {
        const filters = [];
        filters[0] = {
            remove: true,
            name: 'status_notUsed',
        };
        setFilter({ notUsed: false });
        table.getWrappedInstance().customFilter(filters);
    };

    const onRemoveUsedFilter = () => {
        const filters = [];
        filters[0] = {
            remove: true,
            name: 'status_used',
        };

        setFilter({ used: false });
        table.getWrappedInstance().customFilter(filters);
    };

    const onShowNotUsed = () => {
        const filters = [];
        filters[0] = {
            field: 'status',
            operator: 'eq',
            value: VOUCHER_CLAIM_STATUS_ENUM.NotUsed,
            name: 'status_notUsed',
        };
        setFilter({ notUsed: true });
        table.getWrappedInstance().customFilter(filters);
    };

    const onShowUsed = () => {
        const filters = [];
        filters[0] = {
            field: 'status',
            operator: 'eq',
            value: VOUCHER_CLAIM_STATUS_ENUM.Used,
            name: 'status_used',
        };

        setFilter({ used: true });
        table.getWrappedInstance().customFilter(filters);
    };

    const isFilterActive = (filterName: StatusFilterName) => {
        return !!filters[filterName];
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="VoucherClaims" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <div className="actions">
                            <ActionButton text="Add" onClick={onNew} icon="plus" />
                            <ActionButton text="Nastavit jako nepoužitý" onClick={onSetAsUnused} icon="pencil" selection="one" />
                            <ToggableActionButton
                                disabled={isFilterActive('used')}
                                text="Zobrezit nepoužité"
                                onSelected={onShowNotUsed}
                                onNotSelected={onRemoveNotUsedFilter}
                                icon="filter"
                            />
                            <ToggableActionButton
                                disabled={isFilterActive('notUsed')}
                                text="Zobrezit použité"
                                onSelected={onShowUsed}
                                onNotSelected={onRemoveUsedFilter}
                                icon="filter"
                            />
                        </div>
                        <div className="filter" />
                        <VoucherClaimsSearchBox />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'voucherclaims'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            name="VoucherClaimsTable"
                            dataTransformation={VOUCHERCLAIM}
                        >
                            <Column field="selected" width="50" />
                            <Column field={nameof('validFromUtc')} width="150" cell={(props) => <DateTimeCell {...props} />} title={t('ValidFrom')} />
                            <Column field={nameof('validToUtc')} width="150" cell={(props) => <DateTimeCell {...props} />} title={t('ValidTo')} />
                            <Column
                                field={nameof('statusEnumDescription')}
                                width="150"
                                cell={EnumCell(enums.VOUCHER_CLAIM_STATUS)}
                                title={t('Status')}
                            />
                            <Column
                                field={nameof('activeFromUtc')}
                                width="150"
                                cell={(props) => <DateTimeCell {...props} />}
                                title={t('ActiveFrom')}
                            />
                            <Column field={nameof('activeToUtc')} width="150" cell={(props) => <DateTimeCell {...props} />} title={t('ActiveTo')} />
                            <Column field={nameof('ean')} cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)} title={t('Ean')} />
                        </Table>
                    </div>
                </div>

                <SimpleDialog open={visible} onClose={toggleDialog} />
            </div>
        </div>
    );
};

interface SimpleDialogProps {
    open: boolean;
    onClose: (value?: string) => void;
}

function SimpleDialog(props: SimpleDialogProps) {
    const { onClose, open } = props;

    const handleClose = () => {
        onClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
                component: 'form',
                onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                    event.preventDefault();
                    const formData = new FormData(event.currentTarget);
                    const formJson = Object.fromEntries((formData as any).entries());
                    const note = formJson.note;

                    onClose(note);
                },
            }}
        >
            <DialogTitle fontSize={20}>Nastavit poukaz jako nepoužitý</DialogTitle>
            <DialogContent>
                <DialogContentText fontSize={16}>Po nastavení jako nepoužitý uveďte důvod obnovy</DialogContentText>
                <TextField
                    autoFocus
                    required
                    margin="dense"
                    id="name"
                    name="note"
                    label="Důvod obnovy"
                    type="area"
                    fullWidth
                    variant="standard"
                    multiline
                    rows={4}
                    slotProps={{
                        input: {
                            style: {
                                fontSize: 16,
                            },
                        },
                        inputLabel: {
                            size: 'normal',
                            style: {
                                fontSize: 16,
                            },
                        },
                    }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Zpět</Button>
                <Button type="submit">Nastavit</Button>
            </DialogActions>
        </Dialog>
    );
}

const mapDispatchToProps = (dispatch: Dispatch<any>): VoucherClaimDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        setAsUnusedAction: bindActionCreators(setAsUnused, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(VoucherClaims);
