import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import TargetCampaignDetail from './targetCampaignDetail';
import { createTargetCampaign } from '../../actions/targetCampaignActions';
import { navigateToTargetCampaigns } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import TargetedCampaign from '../../entities/targetedCampaign';

const initialValues = new TargetedCampaign({ msisdn: '', campaignCode: ''});

interface TargetCampaignDetailDetailCreateProps extends BaseDetailProps<TargetedCampaign> {}

const TargetCampaignDetailDetailCreate = ({ submitAction, cancelAction, t }: TargetCampaignDetailDetailCreateProps) => {
    return (
        <TargetCampaignDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('TargetCampaignCreateLabel')}
            cancelAction={cancelAction}
            t={t}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<TargetCampaignDetailDetailCreateProps>): BaseDetailDispatchProps<TargetedCampaign> => {
    return {
        submitAction: bindActionCreators(createTargetCampaign, dispatch),
        cancelAction: bindActionCreators(navigateToTargetCampaigns, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(TargetCampaignDetailDetailCreate);
