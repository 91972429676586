import VoucherClaimCreateMutation from '../entities/voucherClaimCreateMutation';

const VoucherClaimCreateItemTransformationBeforeSave = (item: VoucherClaimCreateMutation) => {
    const transformedData = {
        ...item      
    } as { [key: string]: any };

    return transformedData;
};

export default VoucherClaimCreateItemTransformationBeforeSave;