export const NEWS = 'NEWS';
export const COMPETITION = 'COMPETITION';
export const RATING = 'RATING';
export const OUTAGE_NOTIFICATION = 'OUTAGE_NOTIFICATION';
export const RATING_SETTINS = 'RATING_SETTINGS';
export const REWARD = 'REWARD';
export const TARGET_CAMPAIGN = 'TARGET_CAMPAIGN';
export const SIM_CARD_TYPE = 'SIM_CARD_TYPE';
export const AFFILIATE_PROGRAM_PARTNER = 'AFFILIATE_PROGRAM_PARTNER';
export const AFFILIATE_PROGRAM_REWARD = 'AFFILIATE_PROGRAM_REWARD';
export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS = 'AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS';
export const ROLE = 'ROLE';
export const GAME_CLAN = 'GAME_CLAN';
export const TEXT = 'TEXT';
export const LINK = 'LINK';
export const DATASOURCE = 'DATASOURCE';
export const DATASOURCEQUERYCONTEXT = 'DATASOURCEQUERYCONTEXT';
export const PUSHNOTIFICATIONCONFIGURATION = 'PUSHNOTIFICATIONCONFIGURATION';
export const PUSHNOTIFICATIONTASK = 'PUSHNOTIFICATIONTASK';
export const EVENTTRIGGERCONFIGURATION = 'EVENTTRIGGERCONFIGURATION';
export const VOUCHERCLAIM = 'VOUCHERCLAIM';

/*exportTransformationType*/
