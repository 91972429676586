import VoucherClaim from '../entities/voucherClaim';

const VoucherClaimDataTransformationAfterFetch = (data: any[]) => {
    return data.map((row, index) => {
        return new VoucherClaim(row, index);
    });
};

export default VoucherClaimDataTransformationAfterFetch;

