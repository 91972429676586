import { EnumStringDescription, getEnumItemByStringValue, TARGETED_CAMPAIGN_CODES } from '../common/enums';

export default class TargetedCampaign {
    id: number;
    campaignCode: string;
    msisdn: string;
    dateOfCreate: Date | null | undefined;
    dateOfModified: Date | null | undefined;
    campaignCodeDescription: EnumStringDescription | undefined;

    constructor(properties: Partial<TargetedCampaign> & Pick<TargetedCampaign, 'campaignCode'>) {
        this.id = 0;
        this.campaignCode = '';
        this.msisdn = '';

        this.dateOfCreate = null;
        this.dateOfModified = null;

        Object.assign(this, properties);

        this.dateOfCreate = properties.dateOfCreate ? new Date(properties.dateOfCreate) : properties.dateOfCreate;
        this.dateOfModified = properties.dateOfModified ? new Date(properties.dateOfModified) : properties.dateOfModified;
        this.campaignCodeDescription = getEnumItemByStringValue(properties.campaignCode, TARGETED_CAMPAIGN_CODES);
    }
}
