import * as types from '../actions/actionTypes';
import { tableInitialState } from './initialStates';
import dataTransformation from '../data-transformations/dataTransformations';

export const getRowById = (state, id) => {
    return state.table.cache.data.find((row) => row.id == id);
};

export const getTable = (state) => {
    return state.table;
};

const row = (state, index, action) => {
    switch (action.type) {
        case types.TABLE_SELECT_ROWS: {
            if (index >= action.min && index <= action.max) {
                return {
                    ...state,
                    selected: action.selected,
                };
            } else {
                return { ...state };
            }
        }
        case types.TABLE_DESELECT_ALL_ROWS: {
            return {
                ...state,
                selected: false,
            };
        }
        case types.TABLE_SELECT_ALL_ROWS: {
            return {
                ...state,
                selected: action.checked,
            };
        }
        default:
            return state;
    }
};

const table = (state = tableInitialState, action) => {
    switch (action.type) {
        case types.TABLE_FILTER_CHANGE: {
            let keys = state.filter.map((a) => {
                return a.name;
            });

            let newFilters = action.filter.filter((e) => {
                return keys.indexOf(e.name) === -1;
            });

            let updatedFilters = state.filter.map((f) => {
                let update = action.filter.find((n) => {
                    return n.name === f.name;
                });
                if (update) {
                    return update;
                }

                return f;
            });

            return {
                ...state,
                filter: updatedFilters.concat(newFilters),
            };
        }
        case types.TABLE_FILTER_CLEAR: {
            return {
                ...state,
                filter: [],
            };
        }
        case types.TABLE_FETCH_DATA: {
            action.data.forEach((o) => {
                o.selected = false;
            });

            let data = action.data;
            if (state.dataTransformation) {
                data = dataTransformation(state.dataTransformation)(data);
            }

            return {
                ...state,
                total: action.recordsFiltered,
                data: data.slice(state.skip - action.skip, state.skip - action.skip + state.take),
                cache: {
                    data: data,
                    skip: action.skip,
                    take: action.take,
                },
                loaded: true,
            };
        }
        case types.TABLE_PAGE_CHANGE: {
            return {
                ...state,
                skip: action.skip,
                take: action.take,
            };
        }
        case types.TABLE_SORT_CHANGE: {
            return {
                ...state,
                sort: action.sort,
            };
        }
        case types.TABLE_RESET: {
            return {
                ...tableInitialState,
                url: action.url,
                actionUrl: action.actionUrl,
                columns: action.columns,
            };
        }
        case types.TABLE_CACHE_DATA: {
            return {
                ...state,
                data: state.cache.data.slice(state.skip - state.cache.skip, state.skip - state.cache.skip + state.take),
            };
        }
        case types.TABLE_SELECT_ROW: {
            let index = state.data
                .map((i) => {
                    return i.id;
                })
                .indexOf(action.id);
            if (index !== -1) {
                let clone = {
                    ...state.data[index],
                    selected: action.selected,
                };

                let result = {
                    ...state,
                    data: [...state.data.slice(0, index), clone, ...state.data.slice(index + 1)],
                };

                return {
                    ...result,
                    selected: result.data.filter((i) => {
                        return i.selected === true;
                    }).length,
                    selectedRow: clone,
                };
            } else {
                return state;
            }
        }
        case types.TABLE_DESELECT_ALL_ROWS: {
            let result = {
                ...state,
                data: state.data.map((i, index) => row(i, index, action)),
            };

            return {
                ...result,
                selected: result.data.filter((i) => {
                    return i.selected === true;
                }).length,
                selectedRow: undefined,
            };
        }
        case types.TABLE_SELECT_ROWS: {
            let result = {
                ...state,
                data: state.data.map((i, index) => row(i, index, action)),
            };

            const countOfSelected = result.data.filter((i) => {
                return i.selected === true;
            }).length;

            let selectedRow = undefined;
            if (countOfSelected === 1) {
                selectedRow = result.data.filter((i) => {
                    return i.selected === true;
                })[0];
            }

            return {
                ...result,
                selected: countOfSelected,
                selectedRow: selectedRow,
            };
        }
        case types.TABLE_SELECT_ALL_ROWS: {
            let result = {
                ...state,
                data: state.data.map((i, index) => row(i, index, action)),
            };

            return {
                ...result,
                selected: result.data.filter((i) => {
                    return i.selected === true;
                }).length,
                selectedRow: undefined,
            };
        }
        case types.TABLE_REMOVE_ROWS: {
            let result = {
                ...state,
                data: state.data.filter((i) => {
                    return !action.ids.includes(i.id);
                }),
            };
            return {
                ...result,
                selected: result.data.filter((i) => {
                    return i.selected === true;
                }).length,
                selectedRow: undefined,
            };
        }
        case types.TABLE_DATA_TRANSFORMATION_CHANGE: {
            return {
                ...state,
                dataTransformation: action.dataTransformation,
            };
        }
        case types.TABLE_ADD_ROW: {
            let rowAsArray = [action.row];

            if (state.dataTransformation) {
                rowAsArray = dataTransformation(state.dataTransformation)(rowAsArray);
            }

            const newData = state.data.concat(rowAsArray);
            /*  newData.forEach(o => {
        o.selected = false;
      }); */

            return {
                ...state,
                total: newData.length,
                data: newData,
                cache: {
                    ...state.cache,
                    data: newData,
                },
            };
        }

        case types.TABLE_AFTER_LOAD_DATA_ACTION: {
            return { ...state, afterLoadDataActionType: action.afterLoadDataActionType };
        }

        default:
            return state;
    }
};

export default table;
