import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../actions/tableActions';
import SubmitButton from '../common/SubmitButton';
import TranslatedTextBox from '../common/TranslatedTextBox';
import Translation from '../common/Translation';

class TargetCampaignSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            msisdn: '',
            campaignCode: '',
        };
    }
    setFilter = (msisdn, campaignCode) => {
        this.props.actions.filterClear();
        
        if (msisdn && campaignCode) {
            this.props.actions.filterChange([
                {
                    name: 'msisdn',
                    field: 'msisdn',
                    operator: 'equal',
                    value: msisdn,
                    logicalOperator: 0,
                },
                {
                    name: 'CampaignCode',
                    field: 'CampaignCode',
                    operator: 'equal',
                    value: campaignCode,
                    logicalOperator: 0,
                },
            ]);
        } else if (msisdn) {
            this.props.actions.filterChange([
                {
                    name: 'msisdn',
                    field: 'msisdn',
                    operator: 'equal',
                    value: msisdn,
                    logicalOperator: 0,
                },
            ]);
        } else if (campaignCode) {
            this.props.actions.filterChange([
                {
                    name: 'CampaignCode',
                    field: 'CampaignCode',
                    operator: 'equal',
                    value: campaignCode,
                    logicalOperator: 0,
                },
            ]);
        } 
    };

    clear = () => {
        this.setState({ msisdn: '', campaignCode: '' });
        this.setFilter('', '');
    };

    handleSearch = (e) => {
        e.preventDefault();
        const { msisdn, campaignCode } = this.state;

        this.setFilter(msisdn, campaignCode);
    };

    handleChangeMsisdn = (e) => {
        this.setState({ msisdn: e.target.value });
    };

    handleChangeCampaignCode = (e) => {
        this.setState({ campaignCode: e.target.value });
    };

    render() {
        return (
            <div className="search">
                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6">
                        <TranslatedTextBox name="msisdn" value={this.state.msisdn} onChange={this.handleChangeMsisdn} placeholder="Msisdn" />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                        <TranslatedTextBox
                            name="campaignCode"
                            value={this.state.campaignCode}
                            onChange={this.handleChangeCampaignCode}
                            placeholder="CampaignCode"
                        />
                    </label>
                </div>
                &nbsp;
                {(this.state.msisdn || this.state.campaignCode) && <span className={`icon icon-cancel`} onClick={this.clear} />}
                <SubmitButton onClick={this.handleSearch}>
                    <Translation tKey="Search" />
                </SubmitButton>
            </div>
        );
    }
}

TargetCampaignSearchBox.propTypes = {
    actions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(appActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps, null, { withRef: true })(TargetCampaignSearchBox);
