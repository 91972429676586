import {
    CAMPRO_MODULES,
    EnumDescription,
    getEnumItemByValue,
    getEnumItemsByFlaggedValue,
    VOUCHER_CLAIM_STATUS,
    VOUCHER_CLAIM_STATUS_ENUM,
} from '../common/enums';

export default class VoucherClaim {
    id: number;
    extId: string;
    ean: string;
    status: number;
    statusEnumDescription: EnumDescription | undefined;
    voucherValueText: string;
    validFromUtc?: Date;
    validToUtc?: Date;
    activeFromUtc?: Date;
    activeToUtc?: Date;

    constructor(properties: Partial<VoucherClaim> & Pick<VoucherClaim, 'status'>, id: number) {
        this.id = id;
        this.extId = '';
        this.ean = '';
        this.status = VOUCHER_CLAIM_STATUS_ENUM.NotUsed;
        this.voucherValueText = '';

        Object.assign(this, properties);

        this.statusEnumDescription = getEnumItemByValue(properties.status, VOUCHER_CLAIM_STATUS);
        this.validFromUtc =  properties.validFromUtc ? new Date(properties.validFromUtc) : properties.validFromUtc;
        this.validToUtc =  properties.validToUtc ? new Date(properties.validToUtc) : properties.validToUtc;
        this.activeFromUtc = properties.activeFromUtc ? new Date(properties.activeFromUtc) : properties.activeFromUtc;
        this.activeToUtc = properties.activeToUtc ? new Date(properties.activeToUtc) : properties.activeToUtc;
    }
}
